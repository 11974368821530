@import url("https://fonts.googleapis.com/css2?family=Almarai:wght@300;400;700&family=Tajawal:wght@300;400;500;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Almarai", sans-serif;
  background-color: #2b2d42;
  color: #edf2f4;
}

.container {
  max-width: 1140px;
  margin: 0 auto;
  padding: 0 20px;
}
