header {
  padding: 20px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .btns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    button {
      span {
        margin: 0 5px;
        display: block;
      }
    }
  }

  @include responsive-tablet {
    flex-direction: column;
    gap: 30px;
    h1 {
      font-size: 1.7em;
    }
    .btns {
      flex-wrap: wrap;
      p {
        display: block;
        width: 100%;
        flex-basis: 100%;
        text-align: center;
        margin: 10px 0;
      }
      button span {
        display: none;
      }
    }
  }
}
