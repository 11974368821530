.form {
  &-group {
    margin-bottom: 10px;
  }
  &-inline {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    margin-bottom: 30px;
  }
  label {
    display: block;
    margin-bottom: 10px;
  }
  input {
    width: 100%;
    padding: 10px;
    font-family: inherit;
    border: 1px solid #8d99ae;
    border-radius: 4px;

    &:focus {
      outline: none;
      border: 1px solid #2b2d42;
    }
  }

  .increment-decrement {
    display: flex;
    align-items: center;
    gap: 20px;
    button {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: #ef233c;
      border: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0 0 15px 2px rgba(217, 4, 39, 0.15);
      color: #fff;
      cursor: pointer;
    }
  }
}
