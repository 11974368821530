.loader {
  position: relative;
  margin-top: 10rem;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;

  &__inner {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border: 5px solid #fff;
    border-top-color: #ef233c;
    animation: loader 1s infinite linear;
    animation-delay: 0.5s;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    animation-play-state: running;
    background-color: transparent;
    z-index: 9999;

    @keyframes loader {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}
