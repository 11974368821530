.btn {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  border: 0;
  background-color: #ef233c;
  box-shadow: 0 4px #d90429;
  cursor: pointer;
  font-size: 16px;
  color: #fff;
  border-radius: 3px;
  transition: all 0.2s;
  position: relative;

  &:hover {
    box-shadow: 0 2px #d90429;
    transform: translateY(2px);
  }
}
