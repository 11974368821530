ul.list {
  list-style: none;
  counter-reset: webcounter;

  li {
    background-color: #edf2f4;
    color: #2b2d42;
    padding: 20px 40px;
    border-radius: 5px;
    margin: 40px 0;
    box-shadow: 0 4px #8d99ae;
    position: relative;
    max-width: calc(100% - 60px);

    @include responsive-tablet {
      max-width: 100%;
    }

    &.selected {
      background-color: #c5d1e3;
      box-shadow: 0 2px #8d99ae;
      transform: translateY(2px);
      &::before {
        background-color: rgb(193, 36, 54);
      }
    }

    .name span {
      font-size: 14px;
    }

    h5 {
      font-size: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;

      @include responsive-tablet {
        flex-direction: column;
        align-items: stretch;
        gap: 10px;
      }
    }

    .score {
      margin-left: 5px;
      color: #ef233c;
      flex-basis: 40%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include responsive-tablet {
        flex-basis: 100%;
      }
      span {
        text-align: center;
        .small {
          display: block;
          font-weight: 500;
          font-size: 9px;
          color: #8d99ae;
        }
      }

      // &::before {
      //   content: "[";
      // }
      // &::after {
      //   content: "]";
      // }
    }

    &::before {
      counter-increment: webcounter;
      content: counter(webcounter);
      position: absolute;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      left: -15px;
      top: -20px;
      background-color: #ef233c;
      box-shadow: 0 0 15px 2px rgba(217, 4, 39, 0.25);
      color: #fff;
      display: block;
      text-align: center;
      font-weight: 600;
      line-height: 40px;
      font-size: 20px;
    }

    &:first-of-type,
    &:nth-of-type(2),
    &:nth-of-type(3) {
      &::after {
        content: "";
        display: block;
        width: 55px;
        height: 55px;
        background-size: cover;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
      }
    }
    &:first-of-type {
      &::after {
        // background-image: url("../img/medal.svg");
      }
    }
    &:nth-of-type(2) {
      &::after {
        // background-image: url("../img/medal\ \(1\).svg");
      }
    }
    &:nth-of-type(3) {
      &::after {
        // background-image: url("../img/medal\ \(2\).svg");
      }
    }

    button.edit-btn {
      position: absolute;
      right: -60px;
      top: 15px;
      span {
        display: none;
        margin: 0 5px;
      }

      @include responsive-tablet {
        position: static;
        margin-top: 10px;
        span {
          display: block;
        }
      }
    }
  }
}
