.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(#2b2d42, 0.9);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: baseline;
  &-content {
    margin-top: 100px;
    width: 500px;
    background: #fff;
    color: #2b2d42;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(#8d99ae, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 10px;
    @include responsive-mobile {
      width: 90%;
    }
  }
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin-bottom: 10px;
    width: 100%;
  }
  &-title {
    font-size: 25px;
    font-weight: bold;
  }
  &-close {
    cursor: pointer;
  }
  &-body {
    padding: 10px;
    width: 100%;
    overflow-y: auto;
  }
  &-footer {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
    padding: 10px;
    width: 100%;
    button {
      width: 100%;
      padding: 10px 20px;
      justify-content: center;
    }
  }
}
